<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <ion-toolbar class="mainToolbar ion-no-border">

        <ion-buttons slot="end" style="width: 100px; margin: 20px 30px 20px 0px;">
          <ion-img v-if="sonara" src="assets/ICONOS/logo-sonara.png"></ion-img>
          <ion-img v-else src="assets/ICONOS/vybroo-logo.svg" style="float: right;"></ion-img>
        </ion-buttons>
        <ion-title>{{ $route.params.id }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-grid class="mainGrid">
        <ion-icon class="l_close_a" button @click="this.$router.push('/tabs/tabReproductor')"
          src="assets/ICONOS/close.svg"></ion-icon>
        <ion-row class="topRowImage ion-justify-content-center ion-align-items-end"
          style="background-image: url('assets/start/fondo-inicio.jpg');">
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center" style="margin-top: 70px;">
          <ion-col size="12" class="mainLoginCol">
            <p class="txtLoginTitle" style="margin-top: 0px;">Funciones de administrador</p>
            <p>Ingrese sus credenciales <span v-if="!sonara">de Vybroo </span>para continuar.</p>
            <form ref="loginForm" @submit.prevent="auth">
              <ion-input placeholder="Usuario" v-model="mLoginUser" class="loginInput" required></ion-input>
              <!-- contraseña con enter para ejecutar auth -->
              <ion-input placeholder="Contraseña" type="password" v-model="mLoginPassword" class="loginInput" required
                @keyup.enter="auth"></ion-input>
              <!-- <p style="color: red;"> {{ message }}</p> -->
              <ion-button id="loginButton" type="submit" fill="solid"
                style=" text-transform: none;font-weight: 600;">Continuar</ion-button>
            </form>
          </ion-col>
        </ion-row>
        <ion-row class="  ion-align-items-center">
          <ion-col class="mailtoCol" v-if="!sonara">
            <a class="mailtoTextButton" href="mailto:contacto@vybroo.com"><ion-text color="dark"> <strong>¿Necesitas
                  ayuda?</strong> contacto@vybroo.com</ion-text></a>
          </ion-col>
          <ion-col class="mailtoCol" v-else>
            <a class="mailtoTextButton" href="mailto:hola@sonara.red"><ion-text color="dark"> <strong>¿Necesitas
                  ayuda?</strong> hola@sonara.red</ion-text></a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
import { alertController, IonContent, IonPage, IonRow, IonCol, IonGrid, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonImg, IonButton, IonButtons } from '@ionic/vue';
import { defineComponent } from '@vue/runtime-core';
import axios from 'axios';
import { Storage } from '@capacitor/storage';
export default defineComponent({
  name: 'LoginAdmin',
  components: { IonContent, IonPage, IonRow, IonCol, IonGrid, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonImg, IonButton, IonButtons },
  data: () => ({
    mLoginUser: '',
    mLoginPassword: '',
    working: false,
    message: '',
    loggedIn: false,
    sonara: false,
    clientData: {},
  }),
  methods: {
    async auth() {
      try {
        this.message = '';
        if (this.working) {
          return;
        }
        this.working = true;

        const formData = new FormData();
        formData.append("username", this.mLoginUser);
        formData.append("password", this.mLoginPassword);

        //borrando sesión previa de storage
        await Storage.remove({ key: 'authToken' });
        console.log("borrando sesión previa de storage");
        await axios({
          method: "POST",
          url: "https://panel.vybroo.net/admin_auth",
          data: formData,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then(async r => {
            console.log(r);
            if (!r.data.success) {
              this.working = false;
              this.presentAlertConfirm("Aviso", r.data.message);
              return;
            }
            if (r.data.token && r.data.success) {
              const value = r.data.token;
              const expires = new Date().getTime() + (43200 * 1000);
              const d = new Date(expires);

              console.log("Token expira a las: " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds());
              Storage.set({
                key: "authToken",
                value: JSON.stringify({ value, expires }),
              });
              Storage.set({
                key: "cabina_admin_user",
                value: this.mLoginUser,
              });
 
              try { 
 
                      const formData2 = new FormData(); 
                      formData2.append("token", value);
                      await axios({
                        method: "POST",
                        url: "https://panel.vybroo.net/cabina/channel_list",
                        data: formData2,
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                        },
                      })
                      .then(async res => {
                      console.log("channel_list", res.data.channels);

                      this.emitter.emit('cabina_admin_user', this.mLoginUser,);
                      this.emitter.emit('cabina_admin_channel_list', res.data.channels,);
                      
                      Storage.set({
                        key: "cabina_admin_channel_list",
                        value: JSON.stringify(res.data.channels),
                        });
                       
                      console.log("======cabina_admin_user================");
                      console.log(r);
                      console.log("======cabina_admin_user================");

                      this.working = false;
                      console.log('Login exitoso, redireccionado a nueva pagina de cabina de admin');

                      //Limpiar campos
                      this.mLoginUser = '';
                      this.mLoginPassword = '';

                      //no continuar hasta que se guarde el token en storage
                      this.getStorageItem('authToken');
                      this.$router.push('/admin/dashboard');
 
                    }).catch(error => {
                      console.log("Error en función validateToken de Dashboard: " + error.message);
                    });
              }catch (error) {
                console.log("Error en función getChannelList de Dashboard: " + error.message);
              } 
            }
          })
          .catch(e => {
            this.working = false;
            console.error("Error", e);
            this.presentAlertConfirm("Error", e);
          });
      } catch (e) {
        this.presentAlertConfirm("Error en función auth de LoginAdmin: " + e.message);
      }

    },
    async getStorageItem(keyName) {
      const ret = await Storage.get({ key: keyName });
      if (ret.value) {
        this.message = ret.value;
        console.log("Exito, admin token de storage: - ", this.message);
      }
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
  },
  mounted: function () {
    console.log('mounted LoginAdmin +++++');
    this.getFullClientData();
    this.emitter.on("sonara", (data) => {
      this.sonara = data;
      console.log("sonara", this.sonara);
    });
    
  }
})
</script>

<style scoped>
*,
body {
  font-family: 'Montserrat', sans-serif;
}

.no-ripple {
  --ripple-color: transparent;
}

ion-toolbar {
  margin-top: 0px;
  --background: none;
}

.mainLoginCol {
  text-align: center;
  max-width: 480px !important;
  margin: 10px auto;
}

.mailtoCol {
  text-align: center;
  margin-top: 20px;
}

#backButton {
  margin-left: 30px;
  height: 20px;
  width: 20px;
  bottom: 0;
}

.txtLoginTitle {
  font-size: 28px;
  font-weight: bold;
}

#loginButton {
  font-weight: bold;
  --background: #C8C8C8;
  --border-radius: 5px;
  font-size: 18px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 30px;
  --padding-end: 30px;
}

.loginInput {
  --background: #282828;
  text-align: left;
  border-radius: 5px;
  width: 90%;
  margin: auto;
  font-size: 20px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --padding-start: 25px;
  margin-bottom: 25px;
}

.mailtoTextButton {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 480px) {
  .txtLoginTitle {
    font-size: 22px;
  }

  #loginButton {
    font-size: 16px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 30px;
    --padding-end: 30px;
  }

  .loginInput {
    font-size: 16px;
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 20px;
    margin-bottom: 20px;
  }

  .mailtoTextButton {
    font-size: 16px;
  }
}
</style>